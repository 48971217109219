import React from 'react'
import {Link, graphql} from 'gatsby'
import Layout from '../components/layout'
import ArticleList from "../components/article-list";
import {getExerpt} from "../environment";
import SEO from "../components/seo";

const AllTemplate = ({data, pageContext}) => (
    <Layout lang="ja" pagePath="/all">
      <SEO title="全ての記事" keywords={[`Pikon log`, `Pikon Inc.`, `技術ブログ`]} lang="ja"/>
      {(() => {
        if (data.allStrapiBlog.edges.length !== 0) {
          return (<ArticleList
                  blogs={data.allStrapiBlog.edges}
                  type="enumeration"
                  lang="ja"
                  heading="全ての記事"
                  pages={pageContext.humanPageNumber + " / " + pageContext.numberOfPages}
                  pageContext={pageContext}
                  pagePath="/all"
              />
          )
        }
        else {
          return (
              <article className="article">
                <section className="article__article-body">
                  <p style={{textAlign: "center", paddingTop:"1rem"}}>まだ記事がありません。</p>
                </section>
              </article>
          )
        }
      })()}
    </Layout>
)

export default AllTemplate

export const query = graphql`
  query AllTemplate($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      skip: $skip,
      limit: $limit,
      filter:{
        status:{
          eq:true
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ){
      edges{
        node{
          id
          title
          publishDate(formatString: "YYYY.MM.DD")
          strapiId
          slug
          status
          isTranslated
          category {
            id
            name
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }  
`